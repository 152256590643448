import React from "react";
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useIntl } from "gatsby-plugin-intl";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const MySwiperComponent = () => {
  const intl = useIntl();

  return (
    <div className="bg-greyy">
      <div className="container py-10 md:py-16 px-5">
        <h2 className="text-center text-3xl  decor mb-12">
          {intl.formatMessage({ id: "reference.title" })}
        </h2>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 9000, disableOnInteraction: false }} // Autoplay settings
          pagination={{ clickable: true }}
          //   scrollbar={{ draggable: true }}
          navigation={false} // Hides navigation arrows
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          style={{ alignItems: "center" }}
          draggable
        >
          <SwiperSlide>
            <div className="bg-white rounded-lg m-5 p-5 flex flex-col justify-center text-center max-w-lg shadow-xl my-10 mx-auto items-center">
              <p className="mt-5 mb-5">
                {intl.formatMessage({ id: "reference.second.content1" })}{" "}
                <span className="font-bold">
                  {" "}
                  {intl.formatMessage({ id: "reference.second.bold" })}
                </span>{" "}
                {intl.formatMessage({ id: "reference.second.content2" })}
              </p>
              <p className="mb-5">
                {intl.formatMessage({ id: "reference.second.author" })}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-lg m-5 p-5 flex flex-col justify-center text-center max-w-lg shadow-xl my-10 mx-auto items-center">
              <p className="mt-5 mb-5">
                {intl.formatMessage({ id: "reference.third.content1" })}{" "}
                <span className="font-bold">
                  {" "}
                  {intl.formatMessage({ id: "reference.third.bold" })}
                </span>{" "}
                {intl.formatMessage({ id: "reference.third.content2" })}
              </p>
              <p className="mb-5">
                {intl.formatMessage({ id: "reference.third.author" })}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-lg m-5 p-5 flex flex-col justify-center text-center max-w-lg shadow-xl my-10 mx-auto items-center">
              <p className="mt-5 mb-5">
                {intl.formatMessage({ id: "reference.fourth.content1" })}{" "}
                <span className="font-bold">
                  {" "}
                  {intl.formatMessage({ id: "reference.fourth.bold" })}
                </span>{" "}
                {intl.formatMessage({ id: "reference.fourth.content2" })}
              </p>
              <p className="mb-5">
                {intl.formatMessage({ id: "reference.fourth.author" })}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-lg m-5 p-5 flex flex-col justify-center text-center max-w-lg shadow-xl my-10 mx-auto items-center">
              <p className="mt-5 mb-5">
                {intl.formatMessage({ id: "reference.fifth.content1" })}{" "}
                <span className="font-bold">
                  {" "}
                  {intl.formatMessage({ id: "reference.fifth.bold" })}
                </span>{" "}
                {intl.formatMessage({ id: "reference.fifth.content2" })}
              </p>
              <p className="mb-5">
                {intl.formatMessage({ id: "reference.fifth.author" })}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-lg m-5 p-5 flex flex-col justify-center text-center max-w-lg shadow-xl my-10 mx-auto items-center">
              <p className="mt-5 mb-5">
                {intl.formatMessage({ id: "reference.first.content1" })}{" "}
                <span className="font-bold">
                  {" "}
                  {intl.formatMessage({ id: "reference.first.bold" })}
                </span>{" "}
                {intl.formatMessage({ id: "reference.first.content2" })}
              </p>
              <p className="mb-5">
                {intl.formatMessage({ id: "reference.first.author" })}
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default MySwiperComponent;
