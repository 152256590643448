import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useIntl } from "gatsby-plugin-intl";

const Price = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          price: file(relativePath: { regex: "/price_bg2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ price }) => (
        <BackgroundImage
          Tag="section"
          id="price"
          fluid={price.childImageSharp.fluid}
        >
          <div className="container md:flex md:flex-row ">
            <div className="md:w-1/2 md:mb-32 p-5 bg-white md:bg-transparent">
              <h2 className="text-center text-3xl mt-20 decor">
                {intl.formatMessage({ id: "price.title" })}
              </h2>
              <p className="max-w-sm m-3 mx-auto mb-5 text-xl  mt-10">
                {intl.formatMessage({ id: "price.content1" })}{" "}
                <span className="font-bold text-center">
                  {intl.formatMessage({ id: "price.price" })}
                </span>
              </p>
              <p className="max-w-sm m-3 mx-auto">
                {intl.formatMessage({ id: "price.content2" })}
              </p>
              <p className="max-w-sm m-3 mx-auto">
                {intl.formatMessage({ id: "price.price2" })}
              </p>
              <p className="max-w-sm m-3 mx-auto">
                {intl.formatMessage({ id: "price.content3" })}
              </p>
              <p className="max-w-sm m-3 mx-auto">
                {intl.formatMessage({ id: "price.content4" })}
              </p>
            </div>
            <div className="md:w-1/2 ">{""}</div>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default Price;
