import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useIntl } from "gatsby-plugin-intl";

const Services = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          allapot: file(relativePath: { regex: "/allapot2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 460) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          muszeres: file(relativePath: { regex: "/muszeres2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 460) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          vedelmi: file(relativePath: { regex: "/vedelmi2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 460) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          lombkorona: file(relativePath: { regex: "/lombkorona1.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 460) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ muszeres, vedelmi, allapot, lombkorona }) => (
        <section id="services">
          <div className="container pt-20 mb-20">
            <h2 className="text-center text-3xl mb-10 decor">
              {intl.formatMessage({ id: "services.title" })}
            </h2>
            <div className="flex flex-col-reverse md:flex md:flex-row mb-10 mt-10">
              <div className="md:w-1/2 ">
                <div style={{ maxWidth: "460px" }} className="mx-auto p-5">
                  <Img
                    fluid={allapot.childImageSharp.fluid}
                    alt="Fa állapotfelmérés"
                    className="rounded-l-lg rounded-r-lg shadow-xl"
                  />
                </div>
              </div>
              <div className="md:w-1/2 flex items-center">
                <div className="max-w-lg p-5 mx-auto">
                  <h3 className="text-2xl mb-5">
                    {intl.formatMessage({ id: "services.first.title" })}
                  </h3>
                  <p className="max-w-sm leading-relaxed">
                    {intl.formatMessage({ id: "services.first.content1" })}{" "}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "services.first.bold" })}
                    </span>{" "}
                    {intl.formatMessage({ id: "services.first.content2" })}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex md:flex-row-reverse mb-10">
              <div className="md:w-1/2">
                <div style={{ maxWidth: "460px" }} className="mx-auto p-5">
                  <Img
                    fluid={muszeres.childImageSharp.fluid}
                    alt="Fa állapotfelmérés"
                    className="rounded-l-lg rounded-r-lg shadow-xl"
                  />
                </div>
              </div>
              <div className="md:w-1/2 flex items-center ">
                <div className="max-w-lg p-5 mx-auto">
                  <h3 className="text-2xl mb-5">
                    {intl.formatMessage({ id: "services.second.title" })}
                  </h3>
                  <p className="max-w-sm leading-relaxed">
                    {intl.formatMessage({ id: "services.second.content1" })}{" "}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "services.second.bold" })}
                    </span>{" "}
                    {intl.formatMessage({ id: "services.second.content2" })}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex md:flex-row mb-10">
              <div className="md:w-1/2">
                <div style={{ maxWidth: "460px" }} className="mx-auto p-5">
                  <Img
                    fluid={vedelmi.childImageSharp.fluid}
                    alt="Fa állapotfelmérés"
                    className="rounded-l-lg rounded-r-lg shadow-xl"
                  />
                </div>
              </div>
              <div className="md:w-1/2 flex items-center">
                <div className="max-w-lg p-5 mx-auto">
                  <h3 className="text-2xl mb-5">
                    {intl.formatMessage({ id: "services.third.title" })}
                  </h3>
                  <p className="max-w-sm leading-relaxed">
                    {intl.formatMessage({ id: "services.third.content1" })}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "services.third.bold" })}
                    </span>{" "}
                    {intl.formatMessage({ id: "services.third.content2" })}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse md:flex md:flex-row-reverse mb-10">
              <div className="md:w-1/2">
                <div style={{ maxWidth: "460px" }} className="mx-auto p-5">
                  <Img
                    fluid={lombkorona.childImageSharp.fluid}
                    alt="Lombkorona"
                    className="rounded-l-lg rounded-r-lg shadow-xl"
                  />
                </div>
              </div>
              <div className="md:w-1/2 flex items-center ">
                <div className="max-w-lg p-5 mx-auto">
                  <h3 className="text-2xl mb-5">
                    {intl.formatMessage({ id: "services.fourth.title" })}
                  </h3>
                  <p className="max-w-sm leading-relaxed">
                    {intl.formatMessage({ id: "services.fourth.content1" })}{" "}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "services.fourth.bold1" })}
                    </span>{" "}
                    {intl.formatMessage({ id: "services.fourth.content2" })}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "services.fourth.bold2" })}
                    </span>{" "}
                    {intl.formatMessage({ id: "services.fourth.content3" })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    />
  );
};
export default Services;
