import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useIntl } from "gatsby-plugin-intl";

const Canopy = () => {
  const intl = useIntl();

  return (
    <StaticQuery
      query={graphql`
        query {
          lombkorona2: file(relativePath: { regex: "/lombkorona2.jpg/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ lombkorona2 }) => (
        <BackgroundImage
          Tag="section"
          id="canopy"
          fluid={lombkorona2.childImageSharp.fluid}
        >
          <div className="container md:flex md:flex-row ">
            <div className="md:w-1/2 md:mb-48 xl:mb-32 p-5 bg-white md:bg-transparent md:mr-48">
              <div className="max-w-md mx-auto mb-12">
                <h2 className="text-center text-3xl mt-20 md:mt-48 xl:mt-20 decor">
                  {intl.formatMessage({ id: "canopy.title" })}
                </h2>
              </div>
              <ul>
                <li>
                  <p className="max-w-sm m-3 mx-auto mt-6">
                    <span className="font-bold">&#8226; </span>
                    {intl.formatMessage({ id: "canopy.first.content1" })}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "canopy.first.bold" })}
                    </span>
                    {intl.formatMessage({ id: "canopy.first.content2" })}
                  </p>
                </li>
                <li>
                  <p className="max-w-sm m-3 mx-auto mt-6">
                    <span className="font-bold">&#8226; </span>
                    {intl.formatMessage({ id: "canopy.second.content1" })}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "canopy.second.bold" })}
                    </span>
                    {intl.formatMessage({ id: "canopy.second.content2" })}
                  </p>
                </li>
                <li>
                  <p className="max-w-sm m-3 mx-auto mt-6">
                    <span className="font-bold">&#8226; </span>
                    {/* {intl.formatMessage({ id: "canopy.third.content1" })} */}
                    <span className="font-bold">
                      {intl.formatMessage({ id: "canopy.third.bold" })}
                    </span>
                    {intl.formatMessage({ id: "canopy.third.content2" })}
                  </p>
                </li>
              </ul>
            </div>
            <div className="md:w-1/2 ">{""}</div>
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default Canopy;
